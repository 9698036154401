<template>
  <component :is="item === undefined ? 'div' : 'b-card'">
    <b-overlay
      :show="item === null"
      rounded="sm"
    >
      <div
        v-if="item"
        class="mt-2 pt-75"
      >
        <b-form>
          <b-row>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                :label="$t('admin.labels.code')"
                label-for="code"
              >
                <b-form-input
                  id="code"
                  v-model="item.code"
                  :state="errors && errors.code ? false : null"
                />

                <b-form-invalid-feedback v-if="errors && errors.code">
                  {{ errors.code[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                :label="$t('admin.labels.status')"
                label-for="status"
                :state="errors && errors.status ? false : null"
              >
                <v-select
                  id="status"
                  v-model="item.status"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="statusOptions"
                  :reduce="val => val.value"
                  :clearable="false"
                  input-id="status"
                />
                <b-form-invalid-feedback v-if="errors && errors.status">
                  {{ errors.status[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>
          <b-tabs>
            <b-tab
              v-for="language in languages"
              :key="language.code"
            >
              <template #title>
                <b-img
                  :src="require('@/assets/images/flags/' + language.code + '.svg')"
                  height="16"
                  width="16"
                  class="mr-1"
                />
                <span class="d-none d-sm-inline">{{ language.title }}</span>
              </template>
              <b-row>
                <b-col
                  cols="24"
                  md="12"
                >
                  <b-form-group
                    :label="$t('admin.labels.title')"
                    :label-for="'title.' + language.code"
                  >
                    <b-form-input
                      :id="'title.' + language.code"
                      v-model="item.title[language.code]"
                      :state="errors && errors['title.' + language.code] ? false : null"
                    />
                    <b-form-invalid-feedback v-if="errors && errors['title.' + language.code]">
                      {{ errors['title.' + language.code][0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="24"
                  md="12"
                >
                  <b-form-group
                    :label="$t('admin.labels.description')"
                    :label-for="'description.' + language.code"
                    :state="errors && errors['description.' + language.code] ? false : null"
                  >
                    <quill-editor
                      :id="'description.' + language.code"
                      v-model="item.description[language.code]"
                      :options="snowOption"
                      :state="errors && errors['description.' + language.code] ? false : null"
                    />
                    <b-form-invalid-feedback v-if="errors && errors['description.' + language.code]">
                      {{ errors['description.' + language.code][0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-tab>
          </b-tabs>

          <!-- Action Buttons -->
          <b-button
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="onSubmit"
          >
            {{ $t('admin.buttons.save') }}
          </b-button>
          <b-button

            type="button"
            variant="outline-secondary"
            :to="{ name: 'admin:notification_templates-index' }"
          >
            {{ $t('admin.buttons.cancel') }}
          </b-button>
        </b-form>
      </div>
    </b-overlay>
  </component>
</template>

<script>
import GlobalMixin from '@/mixins/GlobalMixin'

import router from '@/router'
import _ from 'lodash'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import { mapGetters } from 'vuex'
import { quillEditor } from 'vue-quill-editor'
import InitFileManager from '@/components/InitFileManager'

export default {
  components: {
    InitFileManager,
    quillEditor,
  },
  mixins: [GlobalMixin],
  data() {
    return {
      item: null,
      languages: [],
      snowOption: {
        theme: 'snow',
      },
    }
  },
  computed: {
    ...mapGetters({
      errors: 'validation/errors',
    }),
  },
  async beforeCreate() {
    await this.$http.get('/api/languages')
      .then(response => {
        this.languages = response.data.data
      })

    this.item = await this.transformData()
  },
  destroyed() {
    this.$store.dispatch('validation/clearErrors')
  },
  methods: {
    async onSubmit() {
      const data = {
        title: this.item.title,
        description: this.item.description,
        status: this.item.status,
        code: this.item.code,
      }

      this.$http.post('/api/admin/notification_templates', data)
        .then(() => {
          router.replace({ name: 'notification_templates-index' })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: error.response.data.message,
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          })
        })
    },
    transformData() {
      const data = {
        status: 'enabled',
        title: {},
        description: {},
        code: null,
      }

      _.each(this.languages, language => {
        data.title[language.code] = null
        data.description[language.code] = null
      })

      return data
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/quill.scss';
</style>
